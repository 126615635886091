import axios from '@axios'
import useJwt from '@/auth/jwt/useJwt'
import ability from '@/libs/acl/ability'

export default {
  namespaced: true,
  state: {
    user: {},
    accountSettings: {},
  },
  getters: {
    user: state => state.user,
    accountSettings: state => state.accountSettings,
  },
  mutations: {
    setUser(state, user) {
      localStorage.setItem('userData', JSON.stringify({
        id: user.id,
        role: user.role,
      }))
      ability.update(user.ability)
      state.user = user
    },
    clearUser(state) {
      state.user = {}
    },
    setAccountSettings(state, settings) {
      state.accountSettings = settings
    },
  },
  actions: {
    setUser(ctx, user) {
      ctx.commit('setUser', user)
    },
    setAccountSettings(ctx, accountSettings) {
      ctx.commit('setAccountSettings', accountSettings)
    },
    async getUserCurrent({ getters, dispatch }) {
      if (Object.keys(getters.user).length !== 0) {
        return { status: 200, data: getters.user }
      }
      if (!useJwt.getToken() || !useJwt.getRefreshToken()) {
        return { status: 400, data: null }
      }
      const response = await axios.get('/users/current?include=ability,roles').catch(error => console.log(error))
      if (response.status === 200) {
        const userData = response.data.data
        dispatch('setUser', userData)
        return ({ status: response.status, data: userData })
      }
      return { status: 0 }
    },
    async fetchAccountSettings(ctx) {
      const response = await axios.get('/account-settings/current').catch(error => console.log(error))
      const successStatuses = [200, 201]
      if (successStatuses.includes(response.status)) {
        ctx.commit('setAccountSettings', response.data)
      }
      return response
    },

    async updateAccountSettings(ctx, account) {
      const response = await axios.patch(`/account-settings/${account.id}`, account).catch(error => console.log(error))
      const successStatuses = [200, 201]
      if (successStatuses.includes(response.status)) {
        response.statusText = 'updated'
        ctx.commit('setAccountSettings', account.data)
      }
      return response
    },
  },
}

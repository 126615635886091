class Point {
  static get modelName() {
    return 'Point'
  }

  constructor(point) {
    Object.assign(this, point)
  }
}

export default Point

import axios from '@axios'
import Point from '@/mixins/classes/point'

export default {
  namespaced: true,
  state: {
    pointTypesAndPurposes: {},
    point: {},
  },
  getters: {
    pointTypesAndPurposes: state => state.pointTypesAndPurposes,
    point: state => state.point,
  },
  mutations: {
    setPointTypesAndPurposes(state, pointTypesAndPurposes) {
      state.pointTypesAndPurposes = pointTypesAndPurposes
    },

    setPoint(state, point) {
      state.point = point
    },
  },
  actions: {
    // Point Options
    setPointTypesAndPurposes(ctx, pointTypesAndPurposes) {
      ctx.commit('setPointTypesAndPurposes', pointTypesAndPurposes)
    },
    async getPointTypesAndPurposes({ getters, dispatch }) {
      if (Object.keys(getters.pointTypesAndPurposes).length !== 0) {
        return { status: '200', data: getters.pointTypesAndPurposes }
      }
      const response = await axios.get('/point-types?include=point_purposes').catch(error => console.log(error))
      if (response.status === 200) {
        const pointTypesAndPurposes = response.data.data
        dispatch('setPointTypesAndPurposes', pointTypesAndPurposes)
        return ({ status: response.status, data: pointTypesAndPurposes })
      }
      return { status: 0 }
    },
    // Points
    async addPoint(ctx, point) {
      const response = await axios.post('/points', point).catch(error => console.log(error))
      const successStatuses = [200, 201]
      if (successStatuses.includes(response.status)) {
        response.statusText = 'added'
        ctx.commit('setPoint', response.data)
      }
      return response
    },
    async updatePoint(ctx, point) {
      const response = await axios.patch(`/points/${point.id}`, point.data).catch(error => console.log(error))
      const successStatuses = [200, 201]
      if (successStatuses.includes(response.status)) {
        ctx.commit('setPoint', response.data)
        response.statusText = 'updated'
      }
      return response
    },
    async fetchPoint(ctx, { id }) {
      const response = await axios.get(`/points/${id}?include=user,point_type,point_purpose,main_image,media_files`).catch(error => {
        if (error.response.status === 404) {
          return error.response
        }
        console.log(error)
      })
      const successStatuses = [200, 201]
      if (successStatuses.includes(response.status)) {
        ctx.commit('setPoint', new Point(response.data.data))
      }
      return response
    },
    async deletePoint(ctx, { id }) {
      const response = await axios.delete(`/points/${id}`).catch(error => console.log(error))
      const successStatuses = [200, 201]
      if (successStatuses.includes(response.status)) {
        response.statusText = 'deleted'
      }
      return response
    },
    async fetchPoints(ctx, queryParams) {
      const response = await axios.get('/points?include=user,point_type,point_purpose,main_image,media_files', {
        params: queryParams,
      }).catch(error => console.log(error))
      return response
    },
    // Images
    async addImages(ctx, formData) {
      const response = await axios.post('/images', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }).catch(error => console.log(error))
      const successStatuses = [200, 201]
      if (successStatuses.includes(response.status)) {
        response.statusText = 'added'
      }
      return response
    },
    async deleteImages(ctx, data) {
      const response = await axios.delete(`/points/${data.pointId}/images?image_ids=${data.imageIds}`, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }).catch(error => console.log(error))
      const successStatuses = [200, 201]
      if (successStatuses.includes(response.status)) {
        response.statusText = 'deleted'
      }
      return response
    },
  },
}

import useJwt from '@core/auth/jwt/useJwt'
import axios from '@axios'
import vueCookie from 'vue-cookie'

const { jwt } = useJwt(axios, {
  loginEndpoint: '/token',
  registerEndpoint: '/register',
})

jwt.getToken = () => vueCookie.get(jwt.jwtConfig.storageTokenKeyName)

jwt.getRefreshToken = () => vueCookie.get(jwt.jwtConfig.storageRefreshTokenKeyName)

jwt.setToken = value => vueCookie.set(jwt.jwtConfig.storageTokenKeyName, value)

jwt.setRefreshToken = value => vueCookie.set(jwt.jwtConfig.storageRefreshTokenKeyName, value)

export default jwt

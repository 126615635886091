import * as i18nIsoCountries from 'i18n-iso-countries'
import i18nIsoCountriesLang from 'i18n-iso-countries/langs/en.json'

import * as i18nIsoLanguages from '@cospired/i18n-iso-languages'
import i18nIsoLanguagesLang from '@cospired/i18n-iso-languages/langs/en.json'

const splitKeyValue = data => {
  const res = []
  Object.entries(data)
    .forEach(entry => {
      const [key, value] = entry
      res.push({
        value: key,
        text: value,
      })
    })

  return res
}

export default {
  namespaced: true,
  state: {
    countriesList: [],
    languagesList: [],
  },
  getters: {
    countries: state => state.countriesList,
    languages: state => state.languagesList,
  },
  mutations: {
    INIT_COUNTRIES_LIST(state) {
      i18nIsoCountries.registerLocale(i18nIsoCountriesLang)
      const countries = i18nIsoCountries.getNames('en', { select: 'official' })
      state.countriesList = splitKeyValue(countries)
    },
    INIT_LANGUAGES_LIST(state) {
      i18nIsoLanguages.registerLocale(i18nIsoLanguagesLang)
      const languages = i18nIsoLanguages.getNames('en')
      state.languagesList = splitKeyValue(languages)
    },
  },
  actions: {
    languagesList() {
      i18nIsoLanguages.registerLocale(i18nIsoLanguagesLang)
      const languages = i18nIsoLanguages.getNames('en')
      if (Object.keys(languages).length === 0) {
        return console.error('Сan\'t get languages list')
      }
      return splitKeyValue(languages)
    },
    countriesList() {
      i18nIsoCountries.registerLocale(i18nIsoCountriesLang)
      const countries = i18nIsoCountries.getNames('en', { select: 'official' })
      if (Object.keys(countries).length === 0) {
        return console.error('Сan\'t get languages list')
      }
      return splitKeyValue(countries)
    },
  },
}

import mock from '@/@fake-db/mock'

const data = {
  accountSetting: {
    general: {
      phone: '+380671234567',
      email: 'granger007@hogward.com',
      login: 'johndoe',
      fullName: 'John Doe',
      avatar: require('@/assets/images/portrait/small/avatar-s-11.jpg'),
    },
    info: {
      bio: 'my life details',
      residenceCountry: 'UA',
      residenceCity: 'Kyiv',
      publicFigure: true,
      languages: ['uk', 'en', 'es', 'fr'],
    },
    services: {
      tourGuide: {
        status: 'active',
        purposes: ['culture_and_art', 'sports_and_adventure', 'rest_at_nature'],
      },
      translator: {
        status: 'active',
        languages: ['uk', 'en', 'es', 'fr'],
      },
    },
    social: {
      socialLinks: {
        twitter: 'https://www.twitter.com',
        facebook: 'https://www.facebook.com',
      },
    },
    notification: {
      productUpdates: false,
      newTranslation: true,
      newOrder: true,
      newContentUsage: true,
    },
  },
}
/* eslint-disable global-require */
mock.onGet('/account-setting/data').reply(() => [200, data.accountSetting])
